import React from 'react';

import Page from '../hocs/Page';

export default Page(function Dashboard (props) {
  return (
    <div className="dashboard container-fluid">
      <div className="p-4 bg-white my-4">
        <div className="d-flex justify-content-center mb-3">
          <h4>ダッシュボード</h4>
        </div>
        <div className="text-muted">
          No Contents
        </div>
      </div>
    </div>
  );
});
