const { isEmpty, get } = require('lodash');

const { entries } = Object;

const paymentSchedules = {
  5: { label: '月末締め、翌月5日払い（手数料5%）', feeRate: 5, dateLabel: '5日', },
  10: { label: '月末締め、翌月10日払い（手数料4%）', feeRate: 4, dateLabel: '10日', },
  15: { label: '月末締め、翌月15日払い（手数料3%）', feeRate: 3, dateLabel: '15日', },
  20: { label: '月末締め、翌月20日払い（手数料2%）', feeRate: 2, dateLabel: '20日', },
  25: { label: '月末締め、翌月25日払い（手数料1%）', feeRate: 1, dateLabel: '25日', },
  end: { label: '月末締め、翌月末払い', feeRate: 0, dateLabel: '末', },
};

const basicSettingsStatus = (tenant) => {
  return (['fbaSettings', 'bank'].some(_ => isEmpty(tenant[_]))) ? 'initial'
    : get(tenant.fbaSettings, 'accepted') ? 'accepted'
    : (get(tenant.fbaSettings, 'accepted') === false) ? 'rejected'
    : 'uploaded';
};

const netPriceSettingFields = () => {
  return {
    netPriceRate: {
      type: 'integer',
      label: 'Amazon販売価格に対する卸値の割合 (%)',
      validations: {
        required: v => v != null,
        greaterThan0: v => v > 0,
      },
      initialValue: 90,
    },
  };
};

const paymentScheduleFields = () => {
  return {
    paymentSchedule: {
      type: 'select',
      options: entries(paymentSchedules).map(([k, v]) => ({ value: k, label: v.label })),
      validations: {
        required: v => v != null,
      },
      initialValue: 'end',
    },
  };
};

const adminFields = ({ tenant }) => {
  return {
    salesDirect: {
      type: 'boolean',
      label: '直販する',
      initialValue: false,
      readOnly: _ => tenant.hasExhibited,
    },
    enablesAffiliate: {
      type: 'boolean',
      label: 'アフィリエイト機能を有効にする',
      initialValue: false,
    },
  };
};

module.exports = {
  basicSettingsStatus,
  netPriceSettingFields,
  paymentScheduleFields,
  paymentSchedules,
  adminFields,
};
