import React from 'react';
import numeral from 'numeral';
import qs from 'qs';
import { get, flatten, } from 'lodash';

import { computeProductValues, } from './shared/models/product';

export function fullPathWithParams(params, { pathname, search }) {
  const currentParams = qs.parse(decodeURI(search.slice(1)));
  const newParams = {
    ...currentParams,
    ...params
  };
  const newSearch = qs.stringify(newParams);
  return `${pathname}${newSearch ? `?${newSearch}` : ''}`;
};

export function yen(value) {
  return (
    <>
      <span className="mr-1">&yen;</span>
      <span>{numeral(value).format('0,0')}</span>
    </>
  );
}

export function ordersToRows (orders, productsById, tenantsById = {}, shopsById = {}) {
  return orders.map((order) => {
    const { items: _items, tenantId, shopId, } = order;
    const shipments = flatten([get(order, 'FulfillmentShipment.member', [])]);
    const items = _items.map((item) => {
      const product = get(productsById, [item.itemId]);
      const sku = get(product, 'sku') || item.sku;
      const amazonProduct = get(product, 'amazonProduct');
      const title = get(amazonProduct, 'AttributeSets.ItemAttributes.Title');
      const imageUrl = get(amazonProduct, 'AttributeSets.ItemAttributes.SmallImage.URL');
      const asin = get(amazonProduct, 'Identifiers.MarketplaceASIN.ASIN');
      const itemUrl = `https://www.amazon.co.jp/gp/offer-listing/${asin}`;
      return {
        ...item,
        product,
        sku,
        title,
        imageUrl,
        asin,
        itemUrl,
      };
    });
    const tenant = tenantsById[tenantId];
    const shop = shopsById[shopId];
    return {
      order,
      items,
      tenant,
      shop,
      shipments,
    };
  })
    .filter(_ => _.items.length > 0);
}

export function readFile(file, type = 'readAsText') {
  const reader = new FileReader();
  reader[type](file);
  return new Promise((resolve) => {
    reader.addEventListener('load', _ => resolve(_.target.result));
  });
};

export function highlightText(text, words) {
  return words.reduce((x, word) => {
    return x.replace(word, `<mark>${word}</mark>`);
  }, text);
};
