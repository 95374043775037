const { isEmpty } = require('lodash');

const { entries } = Object;

module.exports = {
  generalFields: () => {
    return {
      notNgWordsText: {
        type: 'text',
        label: 'NGワードの除外ワード(改行区切り)',
        rows: 15,
      },
    };
  },
};
