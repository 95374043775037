import React, { useState } from 'react';
import { Input, Button, Modal, ModalBody, ModalHeader, ModalFooter, } from 'reactstrap';
import { keyBy, pick, isEmpty, get, difference, } from 'lodash';
import Select from 'react-select';
import classnames from 'classnames';
import { toast } from 'react-toastify';
import { useToggle, useList, } from 'react-use';
import numeral from 'numeral';
import { v4 as uuid } from 'uuid';
import { format as formatDate, addHours, } from 'date-fns';

import firebase, { functions } from '../../firebase';
import { batch, getCollectionData, } from '../../shared/firebase';
import { unslash, } from '../../shared/util';
import { malls } from '../../shared/config';
import { basicSettingsStatus } from '../../shared/models/tenant';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import ProgressButton from '../ProgressButton';

const { round } = Math;
const db = firebase.firestore();
const { entries, keys, } = Object;

export default function AmazonProductsModal(props) {
  const { user, isOpen, tenant, onClickClose } = props;
  const products = useCollectionSubscription(tenant.ref.collection('products'), [tenant.id], { initialItems: null });
  const amazonProducts = useCollectionSubscription(tenant.ref.collection('amazonProducts').where('Offers.Offer.ItemCondition', '==', 'New'), [tenant.id], { initialItems: null });
  const inventorySupplies = useCollectionSubscription(tenant.ref.collection('inventorySupplies').where('InStockSupplyQuantity', '>', 0), [tenant.id], { initialItems: null });
  const inventorySuppliesById = keyBy(inventorySupplies, 'id');
  const suppliableAmazonProducts = (amazonProducts || [])
    .filter(_ => get(_, 'Offers.Offer.ItemCondition') === 'New')
    .filter(_ => parseInt(get(inventorySuppliesById, [_.id, 'InStockSupplyQuantity']), 10) > 0);
  const recentSyncAmazonProductsJobs = useCollectionSubscription(tenant.ref.collection('syncAmazonProductsJobs').where('createdAt', '>=', addHours(new Date(), -1)));
  const [keyword, setKeyword] = useState('');
  const productSkus = products?.map(_ => unslash(_.sku));
  const leftAmazonProducts = suppliableAmazonProducts.filter(_ => !productSkus?.includes(_.id));
  const filteredAmazonProducts = isEmpty(keyword) ? leftAmazonProducts : leftAmazonProducts.filter((amazonProduct) => {
    const {
      AttributeSets: { ItemAttributes: { Title: title, }, },
      Identifiers: { MarketplaceASIN: { ASIN: asin, } },
      SellerSKU: sku,
    } = amazonProduct;
    return [title, asin, sku].some(_ => _.match(keyword));
  });
  const [selectedItems, { set: setSelectedItems, push: selectItem, removeAt: removeSelectedItemAt }] = useList([]);
  const unselectItem = _ => removeSelectedItemAt(selectedItems.indexOf(_));
  const isSelecting = selectedItems.length > 0;
  const isLoading = recentSyncAmazonProductsJobs.some(_ => _.status === 'initial');
  const errorJob = recentSyncAmazonProductsJobs.find(_ => _.status === 'failed');
  const onClickLoad = async () => {
    try {
      await batch(db, recentSyncAmazonProductsJobs.filter(_ => _.status === 'failed'), (batch, job) => batch.delete(job.ref));
      await tenant.ref.collection('syncAmazonProductsJobs').add({ status: 'initial', offset: 0, createdAt: new Date() });
    } catch(e) {
      console.error(e);
      toast.error('失敗しました');
    }
  };
  const _basicSettingsStatus = basicSettingsStatus(tenant);
  const onClickAdd = async (amazonProducts) => {
    try {
      await amazonProducts.reduce(async (x, amazonProduct) => {
        await x;

        const [existingProduct] = await getCollectionData(tenant.ref.collection('products').where('sku', '==', amazonProduct.SellerSKU).limit(1));
        if(existingProduct != null) {
          throw { code: 'duplicate' };
        }

        const amount = parseInt(get(amazonProduct, 'Offers.Offer.BuyingPrice.ListingPrice.Amount'), 10);
        const ref = tenant.ref.collection('products').doc(uuid().replace(/-/g, ''));
        const inventorySupply = inventorySuppliesById[amazonProduct.id];
        const netPrice = round(amount * ((tenant.netPriceRate || 90) / 100));
        if(!(netPrice > 0)) throw new Error('卸値が0');

        await ref.set({
          id: ref.id,
          tenantId: tenant.id,
          sku: amazonProduct.SellerSKU,
          createdAt: new Date(),
          updatedAt: new Date(),
          netPrice,
          amazonProduct,
          quantity: parseInt(inventorySupply.InStockSupplyQuantity, 10),
          yahooStatus: 'initial',
          rakutenStatus: 'initial',
          wowmaStatus: 'initial',
          netPriceLogs: [
            { savedAt: new Date(), netPrice, savedBy: pick(user, ['id', 'email', 'displayName']), },
          ],
        });
      }, Promise.resolve());
      toast.success('追加しました');
      setSelectedItems([]);
    } catch(e) {
      console.error(e);
      const message = e.code === 'duplicate' ? '重複登録がありました。やり直してください。' : '失敗しました';
      toast.error(message);
    }
  };

  return (
    <Modal isOpen={isOpen} style={{ minWidth: 1000 }}>
      <ModalHeader>
        Amazon商品取込
      </ModalHeader>
      <ModalBody>
        {
          (products == null || amazonProducts == null || inventorySupplies == null) ? (
            <span className="fas fa-spin fa-spinner" />
          ) : (
            <div>
              {
                !isSelecting ? (
                  <div>
                    <div className="mb-3 d-flex justify-content-end align-items-center">
                      <Button color="primary" onClick={onClickLoad} disabled={isLoading}>
                        <span className={classnames('fas mr-1', { 'fa-sync': !isLoading, 'fa-spin fa-spinner': isLoading })} />
                        読み込み
                      </Button>
                    </div>
                  </div>
                ) : (
                  _basicSettingsStatus === 'accepted' ? (
                    <div className="mb-3 d-flex justify-content-end align-items-center">
                      <div className="d-flex align-items-end">
                        <div>
                          {selectedItems.length} 件を選択中
                        </div>
                        <ProgressButton className="ml-2" process={onClickAdd.bind(null, selectedItems)}>
                          <span className="fas fa-plus mr-1" />
                          追加
                        </ProgressButton>
                      </div>
                    </div>
                  ) : (
                    <div className="alert alert-warning">
                      初期設定が完了していないため商品登録できません
                    </div>
                  )
                )
              }
              <div className="mb-3 d-flex justify-content-start align-items-center">
                <div style={{ width: 350 }}>
                  <Input type="search" value={keyword} onChange={_ => setKeyword(_.target.value)} placeholder="ASIN、SKU、商品名で検索" disabled={selectedItems.length > 0} />
                </div>
              </div>
              {
                isLoading && (
                  <div className="alert alert-info">
                    商品の読み込みには数分かかる場合もあります。
                    <br />
                    今しばらくお待ち下さいませ。
                  </div>
                )
              }
              {
                errorJob != null && (
                  <div className="alert alert-danger">
                    商品の読み込みに失敗しました。
                    {errorJob.errorMessage}
                  </div>
                )
              }
              <table className="table table-bordered">
                <thead className="thead-light text-nowrap">
                  <tr>
                    <th>
                      {
                        filteredAmazonProducts.length > 0 && (
                          <Input type="checkbox" className="position-relative m-0" checked={difference(filteredAmazonProducts, selectedItems).length === 0} onChange={_ => _.target.checked ? setSelectedItems(filteredAmazonProducts) : setSelectedItems([])} />
                        )
                      }
                    </th>
                    <th>画像</th>
                    <th>ASIN</th>
                    <th>SKU</th>
                    <th>商品名</th>
                    <th>Amazon価格</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    filteredAmazonProducts.map((amazonProduct) => {
                      const {
                        AttributeSets: {
                          ItemAttributes: {
                            Title: title,
                            SmallImage: {
                              URL: imageUrl,
                            },
                          },
                        },
                        Identifiers: {
                          MarketplaceASIN: {
                            ASIN: asin,
                          }
                        },
                        Offers: {
                          Offer: {
                            BuyingPrice: {
                              ListingPrice: {
                                Amount: amount,
                              } = {},
                            } = {},
                          } = {},
                        } = {},
                        SellerSKU: sku,
                      } = amazonProduct;
                      
                      return (
                        <tr key={sku}>
                          <td>
                            <Input type="checkbox" className="position-relative m-0" checked={selectedItems.includes(amazonProduct)} onChange={_ => selectedItems.includes(amazonProduct) ? unselectItem(amazonProduct) : selectItem(amazonProduct)} />
                          </td>
                          <td>
                            <img src={imageUrl} style={{ maxWidth: 80 }} />
                          </td>
                          <td>{asin}</td>
                          <td>{sku}</td>
                          <td>{title}</td>
                          <td className="text-nowrap text-right">{numeral(parseInt(amount, 10)).format('0,0')}</td>
                        </tr>
                      );
                    })
                  }
                </tbody>
              </table>
            </div>
          )
        }
      </ModalBody>
      <ModalFooter>
        <Button className="cancel" color="secondary" onClick={onClickClose}>閉じる</Button>
      </ModalFooter>
    </Modal>
  );
};
