const { memoize, groupBy, findKey, chunk, sum, get, orderBy, isEmpty, } = require('lodash');
const { addYears, } = require('date-fns');
const { productStatuses, malls, } = require('../config');

const { keys } = Object;
const mallKeys = keys(malls);

const exhibitingStatus = (product, productShopStatusesGroupedByProductId) => {
  const statuses = ((productShopStatusesGroupedByProductId || {})[product.id] || []).map(_ => _.status);
  return statuses.some(_ => _ === 'exhibiting') ? (
    'exhibiting'
  ) : statuses.some(_ => _ === 'processing') ? (
    'processing'
  ) : statuses.some(_ => ['rejected', 'stopped'].includes(_)) ? (
    'rejected'
  ) : (
    'initial'
  );
};
const getVariationParentAsin = (product) => get(product?.amazonProduct, 'Relationships.VariationParent.Identifiers.MarketplaceASIN.ASIN');
const computeIdInMall = (product, tenant, parentVariation) => {
  const variationParentAsin = getVariationParentAsin(product);
  const isVariationsProduct = variationParentAsin && !get(parentVariation, 'pricePerVariationEnabled');
  const idInMall = isVariationsProduct ? `${tenant?.keyForMall}--${variationParentAsin.toLowerCase()}` : product.id;
  return idInMall;
};
const computeFbaShipmentFee = (amazonProduct) => {
  const { attributes: { item_package_dimensions: [{ height, width, length }] = [{}], item_package_weight: [weight] = [], } = {} } = amazonProduct || {};
  const sizes = orderBy([height, width, length].map(_ => parseFloat(_?.value, 10) * (({ millimeters: 0.01, centimeters: 1 })[_?.unit] || 0)), _ => _, 'desc');
  const sizeTotal = sum(sizes);
  const weightValue = ({ kilograms: parseFloat(weight?.value, 10), grams: parseFloat(weight?.value, 10) * 0.001 })[weight?.unit];
  const sizeType = sizes.every((size, i) => size <= [25, 18, 2][i]) && weightValue <= 0.25 ? 'small'
    : sizes.every((size, i) => size <= [45, 35, 20][i]) && weightValue <= 9 ? 'normal'
    : 'large';
  const fee = ({
    small: 673,
    normal: sizes.every((size, i) => size <= [35, 30, 3.3][i]) && weightValue <= 1 ? 694
      : sizeTotal <= 60 && weightValue <= 2 ? 796
      : sizeTotal <= 80 && weightValue <= 5 ? 887
      : sizeTotal <= 100 && weightValue <= 9 ? 1042 : null,
    large: sizeTotal <= 60 && weightValue <= 2 ? 957
      : sizeTotal <= 80 && weightValue <= 5 ? 968
      : sizeTotal <= 100 && weightValue <= 10 ? 1158
      : sizeTotal <= 120 && weightValue <= 15 ? 1302
      : sizeTotal <= 140 && weightValue <= 20 ? 1347
      : sizeTotal <= 160 && weightValue <= 25 ? 1447
      : sizeTotal <= 180 && weightValue <= 30 ? 2137
      : sizeTotal <= 200 && weightValue <= 40 ? 2674
      : sizeTotal <= 200 && weightValue <= 50 ? 4547
      : sizeTotal <= 220 && weightValue <= 50 ? 5018
      : sizeTotal <= 240 && weightValue <= 50 ? 6975
      : sizeTotal <= 260 && weightValue <= 50 ? 8463 : null,
  })[sizeType];
  return fee;
};
const computeProductValues = (product) => {
  if(product == null) return;

  const { netPrice, amazonProduct, manualFbaShipmentFee, } = product;
  const fbaShipmentFee = computeFbaShipmentFee(amazonProduct);
  const conclusiveFbaShipmentFee = fbaShipmentFee ?? (manualFbaShipmentFee == null || manualFbaShipmentFee === '' ? null : parseInt(manualFbaShipmentFee, 0));
  const profit = netPrice - (parseInt(product.cost, 10) || 0) - conclusiveFbaShipmentFee;
  const profitRate = profit / netPrice;
  return {
    fbaShipmentFee,
    conclusiveFbaShipmentFee,
    profit,
    profitRate,
  };
};

const generateProductStatusChunkMaps = (() => {
  const getExistingBlankChunkIds = memoize((productStatusChunks) => {
    return productStatusChunks.flatMap(_ => Array(Math.max(500 - keys(_.data || {}).length, 0)).fill(_.id));
  });
  return (expander, productStatusChunks, productIds) => {
    const newProductStatusChunkIds = [
      ...getExistingBlankChunkIds(productStatusChunks),
      ...Array(Math.ceil(productIds.length / 500)).fill().flatMap(_ => Array(500).fill(expander.ref.collection('productStatusChunks').doc().id)),
    ];
    return groupBy(productIds, (productId) => {
      const [lastProductStatusChunk] = orderBy(productStatusChunks.filter(_ => _.data?.[productId] != null), _ => _.data[productId].updatedByExpanderAt?.toDate() ?? addYears(new Date(), -100), 'desc');
      return lastProductStatusChunk?.id || newProductStatusChunkIds.shift();
    });
  };
})();

const fieldsForAdminImport = ({ tenants = [], shops = [], } = {}) => {
  const tenantIds = tenants.map(_ => _.id);
  return {
    tenantId: {
      validations: {
        required: v => !isEmpty(v),
        exists: v => tenantIds.includes(v),
      },
    },
    id: {
      validations: {
        required: v => !isEmpty(v),
      },
    },
  };
};

const adminFields = () => {
  return {
    adminNote: {
      type: 'text',
      label: 'メモ',
    },
  };
};


module.exports = {
  exhibitingStatus,
  fieldsForAdminImport,
  computeIdInMall,
  computeFbaShipmentFee,
  computeProductValues,
  generateProductStatusChunkMaps,
  adminFields,
};
