import React, { useState, Fragment } from 'react';
import { Button, } from 'reactstrap';
import { get, isEmpty, orderBy, keyBy, } from 'lodash';
import { format as formatDate, addMonths, startOfDay, endOfDay, } from 'date-fns';
import qs from 'qs';
import { toast } from 'react-toastify';
import numeral from 'numeral';
import Select from 'react-select';
import { useToggle, useCounter, } from 'react-use';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import firebase, { functions } from '../../firebase';
import { yen, ordersToRows } from '../../util';
import { malls, } from '../../shared/config';
import { statuses as orderStatuses, adminFields as orderAdminFields, } from '../../shared/models/order';
import { adminFields as productAdminFields, } from '../../shared/models/product';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useDocumentsFetch from '../hooks/useDocumentsFetch';
import useQueryParams from '../hooks/useQueryParams';
import ModelFormModal from '../modals/ModelFormModal';
import OrderDetailModal from '../modals/OrderDetailModal';
import ExpanderPage from '../hocs/ExpanderPage';
import ExportButton from '../ExportButton';
import EditButton from '../EditButton';
import ProgressButton from '../ProgressButton';
import DeleteButton from '../DeleteButton';
import DateSelector from '../DateSelector';
import QuerySelector from '../QuerySelector';
import QueryInput from '../QueryInput';

const db = firebase.firestore();
const { entries, } = Object;

export default ExpanderPage(function ExpanderDashboard (props) {
  const { user, expander, history, location, location: { search } } = props;
  const {
    startOn: startOnString = formatDate(addMonths(new Date(), -1), 'yyyy-MM-dd'),
    endOn: endOnString = formatDate(new Date(), 'yyyy-MM-dd'),
  } = useQueryParams();
  const startOn = new Date(startOnString);
  const endOn = new Date(endOnString);
  const shops = useCollectionSubscription(expander.ref.collection('shops').orderBy('createdAt'), [expander]);
  const shopsById = keyBy(shops, 'id');
  const notFoundProductOrders = useCollectionSubscription(db.collection('notFoundProductOrders').where('updatedAt', '>=', startOfDay(startOn)).where('updatedAt', '<=', endOfDay(new Date(endOn))).orderBy('updatedAt', 'desc'), [expander, startOnString, endOnString]);
  const filteredNotFoundProductOrders = notFoundProductOrders.filter(_ => _.shop.ref.parent.parent.id === expander.id);

  return (
    <div className="admin-orders container-fluid">
      <div className="p-4 bg-white my-4">
        <div className="d-flex justify-content-center mb-3">
          <h4>商品特定不可注文管理</h4>
        </div>
        <div className="d-flex justify-content-start mb-3">
          <div className="card">
            <div className="card-header">
              絞り込み
            </div>
            <div className="card-body">
              <div className="d-flex align-items-center flex-wrap">
                <DateSelector paramName="startOn" label="開始日" defaultValue={addMonths(new Date(), -1)} />
                <DateSelector className="ml-2" paramName="endOn" label="終了日" defaultValue={new Date()} invalid={startOn > endOn} />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-4 overflow-scroll position-relative" style={{ zIndex: 0 }}>
          {
            filteredNotFoundProductOrders.length > 0 ? (
              <table className="table sticky-table table-bordered">
                <thead className="thead-light text-center">
                  <tr className="text-nowrap">
                    <th>モール注文ID</th>
                    <th>店舗</th>
                    <th>商品ID</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    filteredNotFoundProductOrders.map((notFoundProductOrder) => {
                      const { orderId, ref, shop, productId, updatedAt, } = notFoundProductOrder;
                      return (
                        <tr key={orderId}>
                          <td>
                            {orderId}
                          </td>
                          <td>
                            {shop.name}
                          </td>
                          <td>
                            {productId}
                          </td>
                          <td>
                            <EditButton label="商品ID" itemRef={ref} FormModal={ModelFormModal} formProps={{ title: '商品ID', fields: { productId: { type: 'string', label: '商品ID', } } }} />
                          </td>
                        </tr>
                      );
                    })
                  }
                </tbody>
              </table>
            ) : (
              <div>
                No Data
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
});
